






































import { Vue, Component, Prop, Inject } from "vue-property-decorator";
import { Upload } from "element-ui";
import { subProjectFile } from "@/components/sign/index";
@Component({
    components: {
        "el-upload": Upload
    }
})
export default class FormItemUpfile extends Vue {
    @Prop({
        type: [String, Array, Boolean],
        required: false,
        default: false
    })
    value: any; //表单数据

    @Prop({ type: Object, required: true, default: () => {} })
    formItemOpt: any; //表单参数

    imageUrl: string = "";
    loading: boolean = false;

    @Inject("isdisabled")
    isdisabled!: boolean;

    @Inject("changeSub")
    changeSub!: any;

    filekey: number = NaN;

    async uploadfunc(el: any) {
        this.loading = true;
        const getopt = this.$api.apiList.uploadFile;
        const opt = getopt();
        const file = el.file;
        let filekey: number = 0;

        let formdata = new FormData();
        formdata.append("file", file);
        let subconunt = 0; //提交次数

        let sub: any = async () => {
            subconunt++;
            let result = await this.$axios({
                method: opt.method,
                url: opt.url,
                headers: opt.headers,
                data: formdata,
                timeout: 600000,
                onUploadProgress: progressEvent => {
                    // 使用本地 progress 事件
                    if (progressEvent.lengthComputable) {
                        let num = Math.round(
                            (progressEvent.loaded / progressEvent.total) * 90
                        );
                        this.$emit("onChangeProgress", num, filekey);
                    }
                }
            })
                .then((res: any) => {
                    if (res.data) {
                        if (res.data.path) {
                            return {
                                status: 0,
                                msg: this.$t("scaffold.success"),
                                data: res.data
                            };
                        } else {
                            el.onError({
                                status: 1,
                                msg: `${this.$t(
                                    "scaffold.error"
                                )}：服务器未返回图片路径`,
                                data: res.data
                            });
                            return {
                                status: 1,
                                msg: `${this.$t(
                                    "scaffold.error"
                                )}：服务器未返回图片路径`,
                                data: res.data
                            };
                        }
                    } else {
                        el.onError();
                        return {
                            status: 1,
                            msg: "上传失败：通信成功，但服务器没有返回值"
                        };
                    }
                })
                .catch((err: any) => {
                    if (err.response) {
                        let error: any = err.response.data.errors; //返回值
                        let errKey: any = Object.keys(error); //存放"错误信息key"的数组
                        let errmsg: any = error[errKey[0]][0]; //第一个错误msg
                        el.onError({
                            status: 2,
                            msg: `${this.$t("scaffold.error")}:[${
                                err.response.status
                            }]${errmsg}`,
                            data: err.response
                        });
                        return {
                            status: 2,
                            msg: `${this.$t("scaffold.error")}:[${
                                err.response.status
                            }]${errmsg}`,
                            data: err.response
                        };
                    } else {
                        if (subconunt < 5) {
                            return false;
                        } else {
                            el.onError({
                                status: 3,
                                msg: `${this.$t("scaffold.error")}:[${
                                    err.message
                                }]服务器未返回错误信息`,
                                data: err.config
                            });
                            return {
                                status: 3,
                                msg: `${this.$t("scaffold.error")}:[${
                                    err.message
                                }]服务器未返回错误信息`,
                                data: err.config
                            };
                        }
                    }
                });

            if (result == false) {
                result = await sub();
                return result;
            } else {
                return result;
            }
        };

        let beforeSub: any = () => {
            this.changeSub(true);
            this.$emit(
                "onchange",
                { name: file.name, progress: 1 },
                (key: any) => {
                    filekey = key;
                }
            );
        };

        beforeSub();
        let res = await sub().then((response: any) => {
            return response;
        });

        // console.log(filekey);
        res.filekey = filekey;
        return res;
    }

    // async uploadAli(el: any) {
    //     const file = el.file;
    //     let formdata = new FormData();
    //     formdata.append("file", file);
    //     this.$axios({
    //         url:"https://oss-cn-beijing.aliyuncs.com"
    //     })
    // }

    //文件上传成功钩子
    handleAvatarSuccess(res: any, file: any, fileList: any) {
        if (res.status == 0) {
            this.givePid([res.data], res.filekey);
        } else {
        }
    }

    //文件上传失败钩子
    handleError(err: any, file: any, fileList: any) {
        this.$notify({
            title:
                err.status == 0
                    ? (this.$t("scaffold.success") as string)
                    : (this.$t("scaffold.error") as string),
            message: err.msg,
            type: err.status == 0 ? "success" : "error"
        });
        this.loading = false;
        // console.log(fileList);
    }

    //文件超出限制时的钩子
    overLimit(files: any, fileList: any) {
        this.$notify({
            type: "error",
            title: this.$t("scaffold.error") as string,
            message: "每次只能上传一个文件"
        });
    }

    //上传文件连接到服务器绑定pid
    givePid(data: any, filekey: number) {
        const callback = (num: any) => {
            this.$emit("onChangeProgress", num, filekey);
        };
        subProjectFile(data, this.$route.params.pid, callback).then(
            (res: any) => {
                console.log(res.data)
                if (res.status === 0) {
                    this.change(res.data, filekey);
                } else {
                }
                this.$notify({
                    title:
                        res.status == 0
                            ? (this.$t("scaffold.success") as string)
                            : (this.$t("scaffold.error") as string),
                    message: res.msg,
                    type: res.status == 0 ? "success" : "error"
                });
                this.loading = false;
                // console.log(res);
            }
        );
    }

    beforeAvatarUpload(file: any) {
        // if (!isJPG && !isPNG) {
        //     this.$nextTick(() => {
        //         this.$message.error("上传照片只能是 JPG 或 PNG 格式!");
        //     });
        // }
        // if (!isLt2M) {
        //     this.$nextTick(() => {
        //         this.$message.error("上传照片大小不能超过 2MB!");
        //     });
        // }
    }

    onchange() {
        // console.log("file change");
    }

    // 钩子，选中值发生变化时触发
    change(data: any, filekey: any) {
        this.$emit("onChangeGiveid", data[0].id, filekey);
        this.changeSub(false);
    }

    mounted() {
        if (this.value) {
            // this.imageUrl = this.value;
        } else {
        }
    }
}
